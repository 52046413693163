@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat Alternates", sans-serif;
  scroll-behavior: smooth;
}

/* .hero__background {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.5)
    ),
    url("./assets/heroBg.png");
  background-size: cover;
} */

.grid__ctm {
  display: grid;
  width: 50%;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

@media (max-width: 750px) {
  .grid__ctm {
    width: 100%;
    grid-auto-flow: column;
    overflow: auto;
  }

  .min_width {
    min-width: 280px !important;
  }
}

@media (max-width: 510px) {
  .center {
    display: flex !important;
    justify-content: center !important;
  }
}

@media (max-width: 400px) {
  .scaler {
    transform: scale(0.8);
  }

  .adjust {
    transform: translateX(-3rem) scale(0.6);
  }
}
